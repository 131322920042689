.footer {
  background-color: #141652; /* Same as navbar and hero section */
  color: #ffffff;
  padding: 20px 0;
  text-align: center;
  font-family: "Raleway", sans-serif;
}

.footer a {
  color: #17d2a8; /* Accent color */
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
